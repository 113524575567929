import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const getCVByUsername = (username) => {
  const BaseURL = `${config.http.BASE_URL}/resume-items?user.username=${username}`;
  const token = getElementFromStorage('jwtToken');
  return fetch(BaseURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "cvbuilder": process.env.REACT_APP_CV_BUILDER_HEADER_KEY,
      authorization: `Bearer ${token === 'undefined' ? '' : token}`,
    },
  })
    .then(
      response =>
        response.status !== 200
          ? {
            error: true,
            responseStatus: response.status,
          }
          : response
            .json()
            .then(json => json)
            .catch(() => ({
              error: true,
            })),
      error => {
        throw new Error(error.message);
      },
    );
};

export default getCVByUsername;
