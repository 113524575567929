import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from './Admin.module.css';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { useHistory } from 'react-router-dom';
import { AdminPanelLinks } from './AdminPanelLinks';
import { AdminPanelViews } from './AdminPanelViews';

const AdminPanel = ({ open, setOpen, openLinkModal, setOpenLinksGenerate, tab }) => {
  const history = useHistory();
  const searchParams = window.location.pathname;

  useEffect(() => {
    if (searchParams.includes('admin-panel')) {
      setOpen(true);
    }
  }, [setOpen, searchParams]);

  const onClose = () => {
    if (searchParams.includes('admin-panel')) {
      history.push('/');
    }
    setOpen(false);
  };

  return (
    <Dialog
      style={{ overflow: 'visible', padding: 24 }}
      open={open}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          position: 'relative',
          width: '100%',
          marginBottom: 32,
        }}
      >
        <div style={{ fontSize: 26, fontWeight: 600 }}>
          {tab === 'Links' ? 'Temporary Links' : 'Viewing history'}
        </div>
        {tab === 'Links' && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: 142,
              height: 40,
              right: 54,
              cursor: 'pointer',
              borderRadius: 4,
              border: '1px solid #3F51B5',
              color: '#3F51B5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 16,
            }}
            onClick={setOpenLinksGenerate}
          >
            Generate new
          </div>
        )}
        <CloseIcon
          style={{ position: 'absolute', top: 7.5, right: 15, cursor: 'pointer' }}
          onClick={onClose}
        />
      </div>
      {tab === 'Links' ? (
        <AdminPanelLinks open={open} openLinkModal={openLinkModal} />
      ) : (
        <AdminPanelViews open={open} />
      )}
    </Dialog>
  );
};

export default AdminPanel;
