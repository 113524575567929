import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import GetCVByUsername from '../../../services/GetCVByUsername';
import {
  importData,
  setSelectedUserId,
  setSelectedCvId,
  setResumeItems,
  changeSpinnerValue,
} from '../../../utils';
import axios from 'axios';

import AppContext from '../../../context/AppContext';
import { getMarkIcon } from '../../../helpers/AppHelpers';
import { infoToast } from '../../../shared/toast';

import spinner from '../../../assets/images/spinner.gif';
import SearchBar from './search/SearchBar';

import mainIco from '../../../assets/images/icons/main_icon.png';
import ChangesDialog from '../../../shared/ChangesDialog';
import config from '../../../config';

const UsersTab = ({ dispatch, lastCvUpdate }) => {
  const context = useContext(AppContext);
  const { state } = context;
  const { ownUserInfo, dataChanged, isSale, isTempLink } = state;

  const { t } = useTranslation('rightSidebar');
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const username = query.get('username');
  const resumeId = query.get('resumeId');
  const pathName = query.get('path');

  const [filteredUserData, setFilteredUserData] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [dialogData, setDialogData] = useState({ isOpened: false });

  useEffect(() => {
    if (username) {
      changeSpinnerValue(dispatch, true);
      (async () => {
        const res = await GetCVByUsername(username);
        if (res.length > 0) {
          const cvItem =
            resumeId === null
              ? { data: { ...res.find((item) => item.isMain === true) } }
              : { data: { ...res.find((item) => item.id === resumeId) } };
          const cvId = get(cvItem.data, 'id');
          const userId = get(cvItem.data, 'user._id');
          setResumeItems(dispatch, res);
          setSelectedCvId(dispatch, cvId);
          setSelectedUserId(dispatch, userId);
          importData(dispatch, cvItem);
          axios.post(`${config.http.BASE_URL}/view-history`, { resumeId, path: pathName || "cvbuilder", email: ownUserInfo.username }, {
            headers: { cvbuilder: process.env.REACT_APP_CV_BUILDER_HEADER_KEY },
          }).catch(() => { });
        } else {
          infoToast('CV is not found');
        }
        changeSpinnerValue(dispatch, false);
      })();
    }
  }, [resumeId, username, dispatch, pathName, ownUserInfo.username]);

  const clickByUser = ({ user, resume }) => {
    if (dataChanged && !isSale && !isTempLink) {
      setDialogData({ isOpened: true });
    } else {
      const newUsername = get(user, 'username');
      const cvId = get(resume, '_id');
      history.push(`/user?username=${newUsername}&resumeId=${cvId}`);
    }
  };

  const clickByOwnCv = async () => {
    if (dataChanged && !isSale && !isTempLink) {
      setDialogData({ isOpened: true });
    } else {
      changeSpinnerValue(dispatch, true);
      await GetCVByUsername(ownUserInfo.username).then((response) => {
        if (response.length > 0) {
          const mainCvItem = { data: { ...response.find((item) => item.isMain === true) } };
          setResumeItems(dispatch, response);
          setSelectedCvId(dispatch, '');
          setSelectedUserId(dispatch, null);
          importData(dispatch, mainCvItem);
          history.push('/');
        } else {
          infoToast('CV is not found');
        }
        changeSpinnerValue(dispatch, false);
      });
    }
  };

  const Resume = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const cvs = user.resume_items.map((resume) => (
      <div
        key={get(resume, '_id')}
        onClick={() => clickByUser({ user, resume })}
        className="cursor-pointer flex justify-between items-center 
        rounded border border-slate-200 hover:shadow-md"
      >
        <div className="flex py-1 items-center justify-center first:pt-0 last:pb-0">
          {resume.isMain === true ? <img className="ml-1 w-10 h-10" src={mainIco} alt="" /> : null}
          <div className={`${resume.isMain === true ? '' : 'ml-3'} overflow-hidden`}>
            <p className="text-sm font-medium">{get(resume, 'name')}</p>
            <p className="text-sm text-slate-500 truncate text-gray-500">{get(user, 'username')}</p>
          </div>
        </div>
        <img src={getMarkIcon(resume)} className="w-4 h-4 mr-3" alt="" />
      </div>
    ));

    return (
      <>
        <div
          className={`${isOpen === true ? 'bg-gray-200 ' : ''} 
          rounded hover:shadow-md hover:bg-gray-200  py-1 `}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="ml-3 overflow-hidden">
            <div className='truncate'>{user.username}</div>
            <div className="text-gray-500 text-xs">{`Resume count: ${user.resume_items.length}`}</div>
          </div>
        </div>
        <div>{isOpen && cvs.map((i) => i)}</div>
      </>
    );
  };

  const showCVList = () => {
    return (
      <>
        {filteredUserData.length ? (
          filteredUserData.map((user) => {
            return (
              <div key={user.username} className="cursor-pointer justify-between items-center">
                {user && <Resume user={user} setDialogData={setDialogData} />}
              </div>
            );
          })
        ) : (
          <span>Nothing to show!</span>
        )}
      </>
    );
  };

  return (
    <>
      <ChangesDialog data={dialogData} setDialogData={setDialogData} />
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab">{t('users.title.label')}</div>
        <div
          onClick={clickByOwnCv}
          className="rounded cursor-pointer select-none title_tab btn_my_cv"
        >
          <div>My CV</div>
        </div>
      </div>
      <hr className="my-6" />
      <SearchBar
        ownUserInfo={ownUserInfo}
        setLoadingSpinner={setLoadingSpinner}
        setFilteredUserData={setFilteredUserData}
        lastCvUpdate={lastCvUpdate}
      />
      <hr className="my-6" />
      <div className="flex justify-center">
        {loadingSpinner && <img src={spinner} className="spinner flex" alt="spinner" />}
      </div>
      {!loadingSpinner && showCVList()}
    </>
  );
};

export default UsersTab;
