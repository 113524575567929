import React, { useEffect, useRef, useState } from 'react';
import { Button, DialogActions } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styles from './TempLinkPopup.module.css';
import { extendDateTemporaryLink } from '../../services/GetTemporaryLinks';
import { toast } from 'react-toastify';

const ProlongCalendar = ({ open, setOpen, untilDate }) => {
  const minDate = new Date(untilDate);
  minDate.setDate(minDate.getDate() + 1);
  const [tempDate, setTempDate] = useState(minDate);
  const [isLoading, setLoading] = useState(false);
  const maxDate = new Date(untilDate);
  maxDate.setMonth(maxDate.getMonth() + 1);

  const calendarRef = useRef(null);

  const handleDateChange = (date) => {
    setTempDate(date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        handleClose();
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const formattedNewUntilDate = tempDate.toISOString().split('T')[0];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await extendDateTemporaryLink(open, formattedNewUntilDate);
      toast.info('Link has been prolonged');
      setOpen(false);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  if (!open) return null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div ref={calendarRef} style={{ background: '#FFFF', zIndex: 99, paddingBottom: '12px' }}>
        <DatePicker
          open={true}
          variant="static"
          openTo="date"
          value={minDate}
          onChange={handleDateChange}
          disableToolbar
          showTodayButton
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          className="prolong-calendar"
        />
        <DialogActions
          classes={{
            root: styles.popupControls2,
          }}
        >
          <Button onClick={handleClose} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
            Prolong till {tempDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
          </Button>
        </DialogActions>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default ProlongCalendar;
