import React, {
  useContext, useEffect, useState
} from 'react';

import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { get } from 'lodash';

import {
  saveElementToStorage
} from '../../utils/storage';

import {
  importData,
  isAdmin,
  isTeamManager,
  isSale,
  setSelectedUserId,
  setOwnCvId,
  setOwnUserInfo,
  setResumeItems,
  setActiveResume
} from '../../utils';

import { PostDataLogin } from '../../services/PostDataLogin';
import { changeLoggedIn } from '../../actions';
import AppContext from '../../context/AppContext';
import { errorToast, infoToast } from '../../shared/toast';
import config from '../../config';
import axios from 'axios';
import { EncryptData } from '../../services/EncryptData';
import Loading from '../Loading/loading';

export const authLink = `${process.env.REACT_APP_AUTH_MODULE}#/auth?redirect=${process.env.REACT_APP_REDIRECT_URL}&env=${process.env.REACT_APP_AUTHORIZE_ENV}`;

const Login = (props) => {
  const [staticsEmail, setStaticsEmail] = useState(null)
  const context = useContext(AppContext);
  const { dispatch } = context;

  const history = useHistory();
  const { loggedIn } = props;
  let queryUserName;

  // eslint-disable-next-line react/destructuring-assignment
  if (props.location.state) {
    const query = props.location?.state?.from?.search;
    const findId = query.split('?username=');
    queryUserName = query.split('?username=')[findId.length - 1];
  }

  const getUserData = (accessToken, isToken) => {
    const encryptData = EncryptData(accessToken)
    saveElementToStorage('userAccessToken', encryptData);
    PostDataLogin(encryptData).then(res => {
      if (res.error) {
        showError(isToken);
      } else if (get(res.user, 'resume_items').length === 0) {
        errorToast();
      } else {
        saveElementToStorage('jwtToken', get(res, 'jwt'));
        const resumeItems = get(res.user, 'resume_items');
        let mainCvItem = resumeItems.find((item) => item.isMain === true);
        if (!mainCvItem) {
          mainCvItem = resumeItems[0]
        }
        const buf = mainCvItem.user;
        mainCvItem.user = { _id: buf, id: buf, team_manager: get(res, 'user.team_manager._id'), username: get(res, 'user.username') };
        if (!queryUserName) {
          importData(dispatch, { data: { ...mainCvItem } });
          setSelectedUserId(dispatch, null);
        }
        const userInfo = {
          userId: res?.user?.id || '',
          username: res?.user?.username || '',
          email: res?.user?.email || '',
        };
        setResumeItems(dispatch, resumeItems);
        setOwnUserInfo(dispatch, userInfo);
        setOwnCvId(dispatch, mainCvItem.id);
        setActiveResume(dispatch, mainCvItem.id);
        checkRole(res);
        props.changeLoggedIn(true);
      }
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOCAL) {
      setStaticsEmail(process.env.REACT_APP_LOCAL)
    } else {
      axios.get(`${process.env.REACT_APP_STATICS}/api/user/info`, { withCredentials: true }).then(res => {
        if (res.data.email) {
          setStaticsEmail(res.data.email)
        } else {
          window.location.href = authLink;
        }
      }).catch(err => {
        window.location.href = authLink;
        console.log(err)
      })
    }
  }, [loggedIn])

  useEffect(() => {
    if (staticsEmail) {
      getUserData(staticsEmail, true);
    }
    // eslint-disable-next-line
  }, [staticsEmail])

  if (loggedIn) {
    const { from } = props.location.state || { from: { pathname: '/' } };
    return <Redirect to={from} />;
  }

  const checkRole = (res) => {
    const role = get(res.user.role, 'id');
    const ownUserName = get(res.user, 'username');

    if (role === config.teamManager.id) {
      isTeamManager(dispatch, true);
    }
    if (role === config.manager.id || role === config.teamManager.id) {
      isAdmin(dispatch, true);
    } else if (role === config.saleRole.id) {
      isSale(dispatch, true);
    } else {
      history.push('/');
      if (!!queryUserName && (ownUserName !== queryUserName)) {
        infoToast('Sorry, but you do not have the permissions to review the selected template. Please contact the administrator.');
      }
    }
  };

  const showError = (isToken) => {
    if (!isToken) {
      errorToast();
    } else {
      infoToast('Session expired. Please re-login to renew your session.');
    }
  };

  return <Loading />
};
export default connect(
  state => ({
    loggedIn: state.loggedIn,
  }),
  {
    changeLoggedIn,
  },
)(Login);
