import config from '../config';

export const getUserData = (userId) => {
  const BaseURL = `${config.http.BASE_URL}/resume-items?user=${userId}`;
  return fetch(BaseURL, {
    method: 'GET', headers: {
      "cvbuilder": process.env.REACT_APP_CV_BUILDER_HEADER_KEY,
    },
  }).then(
    (response) =>
      response.status !== 200
        ? {
          error: true,
        }
        : response
          .json()
          .then((json) => json)
          .catch(() => ({
            error: true,
          })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default getUserData;
