import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import spinner from '../../assets/images/spinner.gif';

import { changeLoggedIn } from '../../actions';
import AppContext from '../../context/AppContext';
import { getUserData } from '../../services/GetUserData';
import { PutData } from '../../services/PutData';
import { importData, changeSpinnerValue, setResumeItems } from '../../utils';
import { errorToast, returnChangesToast, successToast, warnToast } from '../toast';
import { clearLocalStorage } from '../../utils/storage';
import { SendDataToGoogleDocs } from '../../services/SendDataToGoogleDocs';
import { GetTemplates } from '../../services/GetTemplates';
import {
  dateFormat,
  getMarkIcon,
  isValidDatePickerDate,
  sortProjects,
} from '../../helpers/AppHelpers';
import ExpiredPopup from '../ExpiredPopup/ExpiredPopup';
import SortProjectsPopup from '../SortProjectsPopup/SortProjectsPopup';
import InvalidDatePopup from '../InvalidDatePopup/InvalidDatePopup';
import ReportPopup from '../ReportPopup/ReportPopup';
import PreSaveDialog from '../PreSaveDialog';
import { BurgerMenu } from './BurgerMenu';
import { HeaderSelect } from './HeaderSelect';
import { ReactComponent as AppsIcon } from '../../assets/images/apps.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/menu-icons/log-out.svg'
import { Box, IconButton } from '@material-ui/core';
import { ToolsMenu } from './ToolsMenu';
import ShareLinkPopup from '../TempLinkPopup/ShareLinkPopup';
import AdminPanel from '../TempLinkPopup/AdminPanel';
import config from '../../config';
import { authLink } from '../../components/Login/Login';

export const Header = (props) => {
  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const {
    data,
    dataChanged,
    showSortPopup,
    loadingSpinner,
    ownCvId,
    ownUserInfo,
    selectedCvId,
    selectedUserId,
    isSale,
    isAdmin,
    isTeamManager,
    resumeItems
  } = state;
  const { t } = useTranslation('header');
  const history = useHistory();
  const [templates, setTemplates] = useState([]);
  const loading = false;
  const [sortPopupAppear, setSortPopupAppear] = useState(false);
  const [invalidDatePopupAppear, setInvalidDatePopupAppear] = useState(false);
  const [ReportPopupAppear, setReportPopupAppear] = useState(false);
  const [invalidDatesArr, setinvalidDatesArr] = useState([]);
  const [manager, setManager] = useState({});
  const [dialogMainData, setDialogMainData] = useState({ isOpened: false });
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [tab, setTab] = useState('Links');

  useEffect(() => {
    let active = true;

    (async () => {
      const templatesRes = await GetTemplates();
      if (active) {
        setTemplates(templatesRes);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const dataManager = data?.user?.team_manager;

  useEffect(() => {
    (async () => {
      if (dataManager) {
        const managerCV = await getUserData(dataManager);
        setManager(managerCV[0]);
      } else {
        setManager({});
      }
    })();
  }, [dataManager]);

  const onLogOut = () => {
    if (!dataChanged ||
      (dataChanged && window.confirm("You have unsaved changes.\nDo you really want to exit?"))) {
      axios.post(`${config.http.BASE_URL}/users/statics/logout`, {}, { withCredentials: true }).then((res) => {
        props.changeLoggedIn(false);
        clearLocalStorage();
        importData(dispatch, null);
        window.location.href = authLink;
      }).catch(err => {
        console.log(err)
      })
    };
  };

  const onSavePutData = () => {
    changeSpinnerValue(dispatch, true);
    PutData(
      { ...data, updatedBy: ownUserInfo.username, isSubmit: false },
      selectedCvId || data._id || ownCvId,
    ).then((res) => {
      changeSpinnerValue(dispatch, false);
      if (!('summary' in res)) {
        if (res?.message && res?.message === 'Wrong resume version') {
          warnToast(
            'CV cannot be saved as a more recent version exists. Please reload the page to continue.',
          );
        } else {
          errorToast();
        }
      } else {
        const temp = resumeItems.map(resumeItem => {
          if (resumeItem.id === res.id) {
            return res;
          }
          return resumeItem;
        });
        setResumeItems(dispatch, temp);
        importData(dispatch, { data: { ...res } });
        successToast();
        props.setLastCvUpdate(new Date().getTime() + Math.random());
      }
    });
  };

  const invalidDateCheck = () => {
    const invalidDateArr = [];
    data.projects.items.forEach((element) => {
      if (
        !(
          isValidDatePickerDate(element.start) &&
          isValidDatePickerDate(element.end) &&
          element.start
        )
      )
        invalidDateArr.push(element.key);
    });
    setinvalidDatesArr(invalidDateArr);
    return invalidDateArr;
  };

  const onSave = () => {
    const invalidDates = invalidDateCheck(data.projects.items);
    if (showSortPopup) {
      if (invalidDates.length > 0) {
        setInvalidDatePopupAppear(true);
      } else {
        setSortPopupAppear(true);
      }
    } else {
      onSavePutData();
    }
  };

  const onCancel = () => {
    changeSpinnerValue(dispatch, true);
    getUserData(selectedUserId || ownUserInfo.userId).then((res) => {
      const selectedCvItem = res.find((item) => item.id === selectedCvId);
      const ownCvItem = res.find((item) => item.id === ownCvId);
      changeSpinnerValue(dispatch, false);
      if (res.error) {
        errorToast();
      } else {
        returnChangesToast();
        setResumeItems(dispatch, res);
        if (selectedCvItem) {
          importData(dispatch, { data: { ...selectedCvItem } });
        } else {
          importData(dispatch, { data: { ...ownCvItem } });
        }
        setResumeItems(dispatch, res);
      }
    });
  };

  useEffect(() => {
    function handler(event) {
      if (dataChanged) event.returnValue = '';
    };
    window.addEventListener('beforeunload', handler);

    return () => { window.removeEventListener('beforeunload', handler); }
  }, [dataChanged]);

  const sendDataToGoogleDocs = (templateUrl, templateType) => {
    changeSpinnerValue(dispatch, true);
    SendDataToGoogleDocs(data, templateUrl, templateType).then((res) => {
      changeSpinnerValue(dispatch, false);
      if (res.error) {
        errorToast();
      } else {
        const url = get(res, 'url');
        window.open(url, '_blank');
      }
    });
  };

  const onVerify = () => {
    const key = 'data.cvStatus';
    const value = data.cvStatus === 'approved' ? 'modified' : 'approved';
    dispatch({ type: 'on_input', payload: { key, value } });
    const keyIsSubmit = 'data.isSubmit';
    const valueIsSubmit = false;
    dispatch({ type: 'on_input', payload: { keyIsSubmit, valueIsSubmit } });
  };

  const isExpiredDate = () => {
    const date = new Date(get(data, 'updatedAt'));
    const today = new Date();
    const diff = Math.floor(today.getTime() - date.getTime());
    const month = 1000 * 60 * 60 * 24 * 30;
    const months = diff / month;
    return months > 6;
  };

  const lastUpdatedDate = () => {
    if ('updatedAt' in data && data.updatedAt !== '')
      return `Edited: ${format(new Date(get(data, 'updatedAt')), dateFormat)}`;
    return '';
  };

  const getUpdatedBy = () => {
    if (data?.updatedBy) return `by ${data.updatedBy}`;
    return '';
  };

  const isValidDescription = () => {
    return data.summary.body.length > 0 && data.summary.body.length < 220;
  };

  const afterSortPutData = (sendData) => {
    changeSpinnerValue(dispatch, true);
    PutData(
      { ...sendData, updatedBy: ownUserInfo.username, isSubmit: false },
      selectedCvId || data._id || ownCvId,
    ).then((res) => {
      changeSpinnerValue(dispatch, false);
      if (!('summary' in res)) {
        if (res?.message && res?.message === 'Wrong resume version') {
          warnToast(
            'CV cannot be saved as a more recent version exists. Please reload the page to continue.',
          );
        } else {
          errorToast();
        }
      } else {
        importData(dispatch, { data: { ...res } });
        successToast();
        props.setLastCvUpdate(new Date().getTime() + Math.random());
      }
    });
  };

  const onAgreeSortPopup = () => {
    setSortPopupAppear(false);
    afterSortPutData(sortProjects(data));
  };

  const onDismissSortPopup = () => {
    setSortPopupAppear(false);
    onSavePutData();
  };

  const onDismissDatesPopup = () => {
    setInvalidDatePopupAppear(false);
  };

  const onSubmit = () => {
    PutData(
      { ...data, updatedBy: ownUserInfo.username, isSubmit: true },
      selectedCvId || ownCvId,
    ).then((res) => {
      changeSpinnerValue(dispatch, false);
      if (!('summary' in res)) {
        if (res?.message && res?.message === 'Wrong resume version') {
          warnToast(
            'CV cannot be sent for approval as a more recent version exists. Please reload the page to continue.',
          );
        } else {
          errorToast();
        }
      } else {
        const temp = resumeItems.map(resumeItem => {
          if (resumeItem.id === res.id) {
            return res;
          }
          return resumeItem;
        });
        setResumeItems(dispatch, temp);
        importData(dispatch, { data: { ...res } });
        successToast('CV was sent for approval successfully');
        props.setLastCvUpdate(new Date().getTime() + Math.random());
      }
    });
  };

  const isTempLink = window.location.toString().match('id=');
  const shouldShowExpiredPopup = selectedUserId === null && get(data, 'cvStatus') === 'expired';
  const statusTooltip = `Status: ${t(`${get(data, 'cvStatus') || 'notApproved'}.label`)}`;
  const approverTooltip = `Approver: ${get(manager, 'summary.firstName')} ${get(manager, 'summary.lastName')}`;

  const startSpinner = () => {
    changeSpinnerValue(dispatch, true);
  }

  const stopSpinner = () => {
    changeSpinnerValue(dispatch, false);
  }

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  }
  const open = Boolean(anchor);
  const disableAnchor = () => setAnchor(null);

  const spinnerData = { start: startSpinner, stop: stopSpinner }

  return (
    <div id="header" className="sticky top-0 z-20 mb-4">
      <div className="text-2xl bg-white flex justify-between items-center h-16 px-8 border border-gray-200 leading-none select-none">
        <ExpiredPopup open={shouldShowExpiredPopup} />

        <div className={`p-3 text-base truncate ${isExpiredDate() ? 'text-red-400' : 'opacity-50'}`}>
          {`${lastUpdatedDate()} ${getUpdatedBy()}`}
        </div>

        {dataChanged && !isSale && (
          <SortProjectsPopup
            open={sortPopupAppear}
            onAgree={onAgreeSortPopup}
            onDismiss={onDismissSortPopup}
          />
        )}

        <InvalidDatePopup
          open={invalidDatePopupAppear}
          invalidDataList={invalidDatesArr}
          onDismiss={onDismissDatesPopup}
        />

        <ReportPopup
          open={ReportPopupAppear}
          onClose={() => setReportPopupAppear(false)}
          senderID={ownUserInfo.userId}
          data={data}
        />

        <PreSaveDialog
          cvStatus={data.cvStatus}
          data={dialogMainData}
          onSave={onSave}
          onSubmit={onSubmit}
          selectedCvId={selectedCvId}
          ownCvId={ownCvId}
          isAdmin={isAdmin}
          onDismiss={() => setDialogMainData({ isOpened: false })}
        />

        {dataChanged && !isSale && !isTempLink && (
          <div className="flex">
            <div
              className={`mr-3 rounded btn_save
        ${isValidDescription() ? 'cursor-default pointer-events-none opacity-50' : ''}`}
              onClick={() => {
                setDialogMainData({ isOpened: true });
              }}
            >
              {t('save.label')}
            </div>

            <div className="rounded btn_cancel mr-3" onClick={onCancel}>
              {t('cancel.label')}
            </div>
          </div>
        )}

        <div className="flex items-center">
          {loadingSpinner && <img src={spinner} className="spinner" alt="spinner" />}
          <HeaderSelect
            dispatch={dispatch}
            state={state}
            history={history}
          />
          {(isSale || isAdmin) && ownCvId !== selectedCvId && selectedCvId !== '' && selectedUserId && (
            <div className="mr-3 rounded btn_save" onClick={() => setReportPopupAppear(true)}>
              Report
            </div>
          )}
          {(!selectedCvId || selectedCvId === ownCvId) &&
            (get(data, 'cvStatus') === 'modified' || get(data, 'cvStatus') === 'draft') &&
            !get(data, 'isSubmit') && (
              <Tooltip title="Send CV for approval">
                <div
                  className={`mr-3 rounded btn_save
                ${dataChanged && !isSale && !isTempLink
                      ? 'cursor-default pointer-events-none opacity-50'
                      : ''
                    }`}
                  onClick={onSubmit}
                >
                  {t('submit.label')}
                </div>
              </Tooltip>
            )}

          <Tooltip
            title={
              <div className="flex flex-col">
                <div>{statusTooltip}</div>
                {manager?.summary && <div>{approverTooltip}</div>}
              </div>
            }
          >
            <div
              className={`p-3 ${isAdmin ? 'hover:bg-gray-200 cursor-pointer' : 'cursor-default'
                } flex items-center`}
              onClick={isAdmin ? onVerify : null}
            >
              {window.innerWidth > 1200 && (
                <span className="mr-2 text-base">
                  {t(`${get(data, 'cvStatus') || 'notApproved'}.label`)}
                </span>
              )}
              <img src={getMarkIcon(data)} className="w-6 h-6" alt="markCheck" />
            </div>
          </Tooltip>

          <BurgerMenu setTab={setTab} setOpenAdmin={setOpenAdmin} setOpenLinkModal={setOpenLinkModal} userID={selectedUserId || ownUserInfo.userId} spinner={spinnerData} templates={templates} sendData={sendDataToGoogleDocs} data={data} />

          <>
            <Box onClick={handleClick}>
              <IconButton style={{ width: "48px", height: "48px" }} >
                <AppsIcon style={{ width: "48px", height: "48px" }} />
              </IconButton>
            </Box>
            <ToolsMenu anchor={anchor} open={open} disableAnchor={disableAnchor} />
          </>

          <Box onClick={onLogOut}>
            <IconButton style={{ width: "42px", height: "42px" }} >
              <LogoutIcon style={{ width: "42px", height: "42px" }} />
            </IconButton>
          </Box>
          {((isSale || isAdmin) && !isTeamManager) && (
            <ShareLinkPopup
              open={openLinkModal}
              setOpen={setOpenLinkModal}
              userID={selectedUserId || ownUserInfo.userId}
              resumeId={data._id}
            />
          )}
          {((isSale || isAdmin) && !isTeamManager) && (
            <AdminPanel
              open={openAdmin}
              setOpen={setOpenAdmin}
              setOpenLinksGenerate={setOpenLinkModal}
              openLinkModal={openLinkModal}
              tab={tab}
              setTab={setTab}
            />
          )}
          {/* <TempLinkPopup open={openLinkModal} setOpen={setOpenLinkModal} userID={selectedUserId || ownUserInfo.userId} resumeId={data._id} /> */}
        </div>
      </div>
    </div >
  );
};

export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
  }),
  {
    changeLoggedIn,
  },
)(Header);
