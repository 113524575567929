import React, { useContext } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import { ReactComponent as LinkIcon } from '../../assets/images/menu-icons/link.svg'
import { ReactComponent as AdminIcon } from '../../assets/images/menu-icons/clock-spin.svg'
import { PdfItems } from './list-parts/PdfItems';
import { GoogleDocItems } from './list-parts/GoogleDocItems';
import AppContext from '../../context/AppContext';

export const BurgerList = ({ setTab, setOpenLinkModal, setOpenAdmin, data, spinner, anchorEl, handleClose, sendData, templates }) => {
  const open = Boolean(anchorEl);
  const context = useContext(AppContext);
  const { state } = context;
  const {
    isSale,
    isAdmin,
    isTeamManager
  } = state;

  const handleLinkClick = () => {
    setTab("Links")
    setOpenAdmin(true);
    handleClose();
  }

  const handleAdminClick = () => {
    setOpenAdmin(true)
    setTab("View")
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ bgcolor: 'background.paper' }}
    >
      <List component="nav">
        <PdfItems spinner={spinner} handleClose={handleClose} />
        <GoogleDocItems templates={templates} sendData={sendData} handleClose={handleClose} />
        {((isSale || isAdmin) && !isTeamManager) && process.env.REACT_APP_AUTHORIZE_ENV !== "prod" && (
          <ListItem button style={{ gap: '8px', padding: '4px 10px' }} onClick={handleAdminClick}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText primary="Viewing history" />
          </ListItem>
        )}

        {((isSale || isAdmin) && !isTeamManager) && process.env.REACT_APP_AUTHORIZE_ENV !== "prod" && (
          <ListItem button style={{ gap: '8px', padding: '4px 10px' }} onClick={handleLinkClick}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Temporary links" />
          </ListItem>
        )}
      </List>
    </Popover>
  )
}
