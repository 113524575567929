import { format } from "date-fns";
import { toast } from "react-toastify";

export const handleCopy = (id) => {
  window.navigator.clipboard.writeText(
    `${process.env.REACT_APP_REDIRECT_URL}resume-view-mode/${id}`,
  );
  toast.info('Link has been copied');
};

export const pathNames = (path) => {
  switch (path) {
    case "HR_module":
      return "HR Module";
    case "Sales_module":
      return "Sales Module";
    case "cvbuilder":
      return "CV Builder";
    case "link":
      return "Temporary link";
    case "rms":
      return "RMS";
    default:
      return "";
  }
}

export const formatDate = (date) => format(date, 'yyyy-MM-dd');