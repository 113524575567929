import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from './components/Login/Login';
import { App } from './components/App/App';
import Loading from './components/Loading/loading';
import LoginRequiredRoute from './components/Login/LoginRequiredRoute';
import ResumeViewMode from './components/ResumeViewMode/ResumeViewMode';

import { changeLoggedIn } from './actions';

class Routes extends Component {
  componentDidMount() {
    const that = this;
    setTimeout(() => { that.props.changeLoggedIn(false) }, 300);
  }

  render() {
    if (this.props.loggedIn === null && !this.isViewMode()) {
      return <Loading />;
    }

    return (
      <Switch>
        <Route exact path="/login" component={Login} />

        <Route exact path="/resume-view-mode/:id" component={ResumeViewMode} />

        <LoginRequiredRoute component={App} />

        <Redirect exact from="/" to="/login" />
      </Switch>
    );
  }

  isViewMode() {
    return this.props.location.pathname.startsWith('/resume-view-mode');
  }
}

export default withRouter(
  connect(
    (state) => ({
      loggedIn: state.loggedIn,
    }),
    {
      changeLoggedIn,
    }
  )(Routes)
);
