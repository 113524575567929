import React, { useState } from 'react'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ReactComponent as ExpandLess } from '../../../assets/images/expand-less.svg'
import { ReactComponent as ExpandMore } from '../../../assets/images/expand-more.svg'
import { ReactComponent as PdfIcon } from '../../../assets/images/menu-icons/icon-pdf.svg'
import { replaceText, translationsForPdf } from '../../../utils'

export const PdfItems = ({ spinner, handleClose }) => {
  const [openPdf, setOpenPdf] = useState(false)

  const handlePdf = async () => {
    spinner.start();

    const printPage = document.getElementById('printPage');
    let htmlContent = printPage.innerHTML;

    const elementsToHide = document.querySelectorAll(".hide-on-print");
    elementsToHide.forEach(element => {
      htmlContent = htmlContent.replace(element.outerHTML, `<div style="display: none;">${element.innerHTML}</div>`);
    });

    printPage.innerHTML = htmlContent;

    const messageElement = document.createElement('div');
    messageElement.innerText = 'Please save document as PDF';
    Object.assign(messageElement.style, {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '20px',
      borderRadius: '8px',
      zIndex: 1000,
      textAlign: 'center',
    });

    document.body.appendChild(messageElement);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    window.print();

    document.body.removeChild(messageElement);

    spinner.stop();
    handleClose();
  };


  const handleRusPdf = async () => {
    spinner.start();

    const printPage = document.getElementById('printPage');
    let htmlContent = printPage.innerHTML;
    htmlContent = replaceText(htmlContent, translationsForPdf);

    const elementsToHide = document.querySelectorAll(".hide-on-print");
    elementsToHide.forEach(element => {
      htmlContent = htmlContent.replace(element.outerHTML, `<div style="display: none;">${element.innerHTML}</div>`);
    });

    printPage.innerHTML = htmlContent;

    const messageElement = document.createElement('div');
    messageElement.innerText = 'Пожалуйста, сохраните документ как PDF';
    Object.assign(messageElement.style, {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '20px',
      borderRadius: '8px',
      zIndex: 1000,
      textAlign: 'center',
    });

    document.body.appendChild(messageElement);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    window.print();
    document.body.removeChild(messageElement);

    spinner.stop();
    handleClose();
  };

  const handleClickPdf = () => {
    setOpenPdf(!openPdf)
  }

  return (
    <>
      <ListItem button onClick={handleClickPdf} style={{ gap: '8px', padding: '4px 10px', borderBottom: !openPdf && '1px solid #EDF2F7' }}>
        <ListItemIcon style={{ minWidth: 0 }}>
          <PdfIcon />
        </ListItemIcon>
        <ListItemText primary="Export as PDF" />
        {openPdf ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openPdf} timeout="auto" unmountOnExit style={{ borderBottom: openPdf && '1px solid #EDF2F7' }} >
        <List component="div" disablePadding>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 12 }} onClick={handlePdf} >
            <ListItemText primary="Softteco EN" />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 12 }} onClick={handleRusPdf}>
            <ListItemText primary="Softteco RU" />
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}
