import crypto from 'crypto-browserify';

export const EncryptData = (data) => {
  const secretKey = process.env.REACT_APP_GOOGLE_CLIENT_SECRET.padEnd(32, ' ');
  const iv = crypto.randomBytes(16)

  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(secretKey), iv);
  let encrypted = cipher.update(data);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString('hex') + ':' + encrypted.toString('hex');
}