import React, { useEffect, useRef, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Button, DialogActions } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './TempLinkPopup.module.css';

const Calendar = ({
  open,
  setOpen,
  selectedDate,
  setSelectedDate,
  customMinDate,
  customMaxDate,
  buttonText,
}) => {
  const [tempDate, setTempDate] = useState(selectedDate);
  const calendarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        handleClose();
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const minDate = new Date();
  if (customMinDate) {
    minDate.setDate(customMinDate);
  } else {
    minDate.setDate(minDate.getDate() + 1);
  }

  const maxDate = new Date();
  if (customMaxDate) {
    maxDate.setDate(customMaxDate);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 1);
  }

  const handleDateChange = (date) => {
    setTempDate(date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setSelectedDate(tempDate);
    setOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div ref={calendarRef} style={{ background: '#FFFF', zIndex: 99, paddingBottom: '12px' }}>
        <DatePicker
          open={open}
          variant="static"
          openTo="date"
          value={selectedDate}
          onChange={handleDateChange}
          disableToolbar
          showTodayButton
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
        />
        <DialogActions
          classes={{
            root: styles.popupControls2,
          }}
        >
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {buttonText || 'Set till'}{' '}
            {tempDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
          </Button>
        </DialogActions>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
