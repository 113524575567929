import React, { useContext, useEffect, useState } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as ProlongIcon } from '../../assets/images/icons/prolong.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/icons/copy.svg';
import { ReactComponent as SortIcon } from '../../assets/images/icons/sort.svg';
import { ReactComponent as EmptyLinksIcon } from '../../assets/images/link-empty.svg';
import ProlongCalendar from './ProlongCalendar';
import { handleCopy } from './helpers';
import AppContext from '../../context/AppContext';
import { GetTemporaryLinks } from '../../services/GetTemporaryLinks';

export const AdminPanelLinks = ({ open, openLinkModal }) => {
  const searchParams = window.location.pathname;
  const selectedLinkId = searchParams.replace('/admin-panel&id=', '') || null;

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

  const [links, setLinks] = useState([]);

  const context = useContext(AppContext);
  const { state } = context;
  const { ownUserInfo, ownCvId, selectedCvId } = state;

  useEffect(() => {
    if (open === false) return;
    if (calendarOpen === true) return;
    (async () => {
      const res = await GetTemporaryLinks(selectedCvId || ownCvId);
      setLinks(res);
    })();
  }, [open, calendarOpen, selectedCvId, ownCvId, openLinkModal]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedLinks = [...links].sort((a, b) => {
      if (key === 'status') {
        const isExpiredA = new Date() < new Date(a.untilDate);
        const isExpiredB = new Date() < new Date(b.untilDate);

        if (isExpiredA === isExpiredB) return 0;
        if (sortConfig.direction === 'asc') {
          return isExpiredA ? 1 : -1;
        } else {
          return isExpiredA ? -1 : 1;
        }
      } else if (key === 'date') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
      return 0;
    });

    setLinks(sortedLinks);
  };

  const handleCalendarOpen = (e, link) => {
    e.stopPropagation();
    setCalendarOpen(link.id);
    const rect = e.currentTarget.getBoundingClientRect();
    const calendarHeight = 350;
    const viewportHeight = window.innerHeight;

    let topPosition;

    if (rect.bottom + calendarHeight > viewportHeight) {
      topPosition = rect.top + window.scrollY - calendarHeight;
    } else {
      topPosition = rect.bottom + window.scrollY;
    }

    setCalendarPosition({
      top: topPosition,
      left: rect.left + window.scrollX,
    });
  };

  return (
    <DialogContent style={{ overflow: 'visible', padding: 0 }}>
      <div style={{ width: '100%', borderRadius: '4px', border: '1px solid #EDF2F7' }}>
        <div style={{ display: 'flex', padding: 12, background: '#EDF2F7' }}>
          <div style={{ flexBasis: '25%' }}>Shared with</div>
          <div
            onClick={() => handleSort('date')}
            style={{
              cursor: 'pointer',
              flexBasis: '20%',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div>Date, time</div> <SortIcon />
          </div>
          <div
            onClick={() => handleSort('status')}
            style={{
              cursor: 'pointer',
              flexBasis: '15%',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div>Status</div> <SortIcon />
          </div>
          <div style={{ flexBasis: '15%' }}>Expiry</div>
          <div style={{ flexBasis: '20%' }}>Shared by</div>
          <div style={{ flexBasis: '5%', textAlign: 'center' }}>Copy</div>
        </div>

        {links?.map((link, index) => {
          const isExpired = new Date() < new Date(link.untilDate);

          return (
            <div
              key={link.id}
              style={{
                display: 'flex',
                padding: '14px 12px',
                alignItems: 'center',
                background: index % 2 === 0 ? '#FFFFFF' : '#F7FAFC',
                border: selectedLinkId === link.id ? '1px solid #0B377F' : '',
              }}
            >
              <div
                style={{
                  flexBasis: `25%`,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {link.email}
              </div>
              <div style={{ flexBasis: '20%' }}>
                {' '}
                {new Date(link.createdAt).toLocaleDateString('en-US', {
                  month: 'long',
                  day: '2-digit',
                })}
                ,{' '}
                {new Date(link.createdAt).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
              <div style={{ flexBasis: '15%' }}>
                {isExpired ? (
                  <div
                    style={{
                      padding: '4px 8px',
                      background: '#EBFAF1',
                      borderRadius: 4,
                      width: 'fit-content',
                    }}
                  >
                    Active
                  </div>
                ) : (
                  <div
                    style={{
                      padding: '4px 8px',
                      background: '#F4F4F4',
                      borderRadius: 4,
                      width: 'fit-content',
                    }}
                  >
                    Expired
                  </div>
                )}
              </div>
              <div
                style={{
                  flexBasis: '15%',
                  display: 'flex',
                  gap: 8,
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                {link.untilDate}
                {ownUserInfo.username === link.creator && (
                  <div
                    onClick={(e) => handleCalendarOpen(e, link)}
                    style={{ cursor: 'pointer', borderRadius: '50%', padding: '1px 2px' }}
                    className={`${calendarOpen === link.id ? 'bg-gray-300' : ''} hover:bg-gray-300`}
                  >
                    <ProlongIcon />
                  </div>
                )}
                {calendarOpen === link.id && (
                  <div
                    style={{
                      position: 'fixed',
                      top: `${calendarPosition.top}px`,
                      left: `${calendarPosition.left}px`,
                      zIndex: 9999,
                      boxShadow: '0px 25px 50px -12px #00000040',
                    }}
                  >
                    <ProlongCalendar
                      open={link.id}
                      setOpen={setCalendarOpen}
                      untilDate={link.untilDate}
                    />
                  </div>
                )}
              </div>
              <div style={{ flexBasis: '20%' }}>{link.creator}</div>
              <div
                style={{
                  flexBasis: '5%',
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <CopyIcon onClick={() => handleCopy(link.id)} />
              </div>
            </div>
          );
        })}
      </div>
      {links?.length === 0 && (
        <div
          style={{
            flexDirection: 'column',
            padding: '36px 0 12px 0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: 16,
          }}
        >
          <EmptyLinksIcon />
          <div style={{ color: '#6C757D', fontSize: 14 }}>No links generated yet</div>
        </div>
      )}
    </DialogContent>
  );
};
