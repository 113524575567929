import config from "../config";

export const GetResumeViews = (resumeId) => {
  const BaseURL = `${config.http.BASE_URL}/view-history?resumeId=${resumeId}`;
  return fetch(BaseURL, {
    method: 'GET', headers: {
      'Content-Type': 'application/json',
      cvbuilder: `${process.env.REACT_APP_CV_BUILDER_HEADER_KEY}`,
    }
  })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json.history)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export const GetResumeViewsByDates = (resumeId, dateFrom, dateTo) => {
  const BaseURL = `${config.http.BASE_URL}/view-history?resumeId=${resumeId}&dateStart=${dateFrom}&dateEnd=${dateTo}`;
  return fetch(BaseURL, {
    method: 'GET', headers: {
      'Content-Type': 'application/json',
      cvbuilder: `${process.env.REACT_APP_CV_BUILDER_HEADER_KEY}`,
    }
  })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json.history)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};