import React, { useEffect, useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { formatDate } from './helpers';
import { Typography } from '@material-ui/core';
import { GetResumeViewsByDates } from '../../services/GetResumeViews';
import Calendar from './Calendar';

export const ViewDatesSection = ({ resumeId, setViews }) => {
  const [datesEdit, setDatesEdit] = useState(false);
  const [calendarFromOpen, setCalendarFromOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
  const [selectedFromDate, setSelectedFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30)),
  );
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  useEffect(() => {
    if (datesEdit) {
      (async () => {
        const formattedFromDate = formatDate(selectedFromDate);
        const formattedToDate = formatDate(selectedToDate);

        const res = await GetResumeViewsByDates(resumeId, formattedFromDate, formattedToDate);
        setViews(res);
      })();
    }
  }, [selectedFromDate, selectedToDate, datesEdit, resumeId, setViews]);

  const handleCalendarOpen = (e, setCalendarOpen) => {
    e.stopPropagation();
    setCalendarOpen((prev) => !prev);
    const rect = e.currentTarget.getBoundingClientRect();
    setCalendarPosition({
      top: rect.bottom + window.scrollY, // позиционируем ниже родителя
      left: rect.left + window.scrollX, // позиционируем влево от родителя
    });
  };

  return (
    <div style={{ position: 'absolute', top: 20, right: 78 }}>
      {datesEdit ? (
        <div style={{ display: 'flex', gap: 12 }}>
          <div
            onClick={(e) => handleCalendarOpen(e, setCalendarFromOpen)}
            style={{
              cursor: 'pointer',
              padding: '10px 14px',
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              width: 'fit-content',
              borderRadius: 3,
              border: '1px solid #EDF2F7',
              position: 'relative',
            }}
          >
            <Typography style={{ fontWeight: 600, fontSize: 14, color: '#505051' }}>
              Views from:
            </Typography>
            <Typography style={{ fontSize: 14, color: '#505051' }}>
              {selectedFromDate.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </Typography>
            <CalendarIcon style={{ marginLeft: 10 }} />
          </div>
          {calendarFromOpen && (
            <div
              style={{
                position: 'fixed',
                top: `${calendarPosition.top}px`,
                left: `${calendarPosition.left}px`,
                zIndex: 9999,
                boxShadow: '0px 25px 50px -12px #00000040',
              }}
            >
              <Calendar
                open={calendarFromOpen}
                setOpen={setCalendarFromOpen}
                selectedDate={selectedFromDate}
                setSelectedDate={setSelectedFromDate}
                customMinDate={new Date().getDate() - 180}
                customMaxDate={new Date().getDate() - 1}
                buttonText={'Set from'}
              />
            </div>
          )}

          <div
            onClick={(e) => handleCalendarOpen(e, setCalendarToOpen)}
            style={{
              cursor: 'pointer',
              padding: '10px 14px',
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              width: 'fit-content',
              borderRadius: 3,
              border: '1px solid #EDF2F7',
              position: 'relative',
            }}
          >
            <Typography style={{ fontWeight: 600, fontSize: 14, color: '#505051' }}>
              Views to:
            </Typography>
            <Typography style={{ fontSize: 14, color: '#505051' }}>
              {selectedToDate.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </Typography>
            <CalendarIcon style={{ marginLeft: 10 }} />
          </div>
          {calendarToOpen && (
            <div
              style={{
                position: 'fixed',
                top: `${calendarPosition.top}px`,
                left: `${calendarPosition.left}px`,
                zIndex: 9999,
                boxShadow: '0px 25px 50px -12px #00000040',
              }}
            >
              <Calendar
                open={calendarToOpen}
                setOpen={setCalendarToOpen}
                selectedDate={selectedToDate}
                setSelectedDate={setSelectedToDate}
                customMinDate={new Date().getDate() - 180}
                customMaxDate={new Date().getDate()}
                buttonText={'Set to'}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDatesEdit(!datesEdit);
          }}
          style={{
            cursor: 'pointer',
            padding: '10px 14px',
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            width: 'fit-content',
            borderRadius: 3,
            border: '1px solid #EDF2F7',
            position: 'relative',
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 14, color: '#505051' }}>
            Period:
          </Typography>
          <Typography style={{ fontSize: 14, color: '#505051' }}>last 30 days</Typography>
          <CalendarIcon style={{ marginLeft: 10 }} />
        </div>
      )}
    </div>
  );
};
