import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const DeleteResumeItemById = (Id) => {
  const reqHeaders = new Headers();
  const token = getElementFromStorage('jwtToken');
  reqHeaders.append('Authorization', `Bearer ${token}`);
  reqHeaders.append('Content-Type', 'application/json');
  reqHeaders.append('cvbuilder', process.env.REACT_APP_CV_BUILDER_HEADER_KEY);
  const BaseURL = `${config.http.BASE_URL}/resume-items/${Id}`;
  return fetch(BaseURL, { method: 'DELETE', headers: reqHeaders }).then(
    (response) =>
      response.status !== 200
        ? {
          error: true,
        }
        : response
          .json()
          .then((json) => json)
          .catch(() => ({
            error: true,
          })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default DeleteResumeItemById;
