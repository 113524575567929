import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
  // Проверяем, нужно ли пропустить авторизацию (для view mode)
  const isViewMode = rest.location.pathname.startsWith('/resume-view-mode');

  return (
    <Route
      {...rest}
      render={(props) =>
        isViewMode || rest.loggedIn ? ( // Если это view mode или пользователь залогинен, рендерим компонент
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default connect((state) => ({
  loggedIn: state.loggedIn,
}))(LoginRequiredRoute);
