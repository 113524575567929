import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../context/AppContext';
import { maxCountResumeItems } from '../../../helpers/AppHelpers';
import { CoppyResumeItemById } from '../../../services/CoppyResumeItemById';
import { DeleteResumeItemById } from '../../../services/DeleteResumeItemById';
import { SetResumeItemAsMain } from '../../../services/SetResumeItemAsMain';
import { importData, setOwnCvId, setResumeItems, setSelectedCvId, changeSpinnerValue } from '../../../utils';
import Item from '../../../shared/CvSettings/Item';

const CvSettingsTab = ({ onChange }) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const { state, dispatch } = context;
  const { resumeItems, selectedUserId, ownUserInfo, activeResume, isSale } = state;
  const { t } = useTranslation('leftSidebar');
  const [isDisabled, setIsDisable] = useState(false);
  const [isCopying, setIsCopying] = useState(false)

  const deleteItem = async (resumeItemId) => {
    changeSpinnerValue(dispatch, true);
    await DeleteResumeItemById(resumeItemId);
    const temp = resumeItems.filter((resume) => resume.id !== resumeItemId);
    setResumeItems(dispatch, temp);
    const mainItem = temp.find(item => item.isMain === true)
    importData(dispatch, { data: { ...mainItem } })
    history.push('/');

    if (selectedUserId !== null) {
      setSelectedCvId(dispatch, mainItem.id);
    }
    else {
      setOwnCvId(dispatch, mainItem.id);
    }

    changeSpinnerValue(dispatch, false);
  };

  const copyItem = async (resumeItemId, resumeItemName) => {
    setIsCopying(true)
    changeSpinnerValue(dispatch, true);
    await CoppyResumeItemById(resumeItemId, resumeItemName).then((json) => {
      const temp = [...resumeItems, json];
      setResumeItems(dispatch, temp);
      setIsCopying(false)
    });
    changeSpinnerValue(dispatch, false);
  };

  const setItemAsMain = async (resumeItemId) => {
    changeSpinnerValue(dispatch, true);

    const userId = selectedUserId || ownUserInfo.userId;
    const resp = await SetResumeItemAsMain(userId, resumeItemId);
    setResumeItems(dispatch, resp);
    const mainItem = resp.find(item => item.isMain === true);
    importData(dispatch, { data: { ...mainItem } });
    history.push('/');

    changeSpinnerValue(dispatch, false);

    if (selectedUserId !== null) {
      setSelectedCvId(dispatch, resumeItemId);
    }
    else {
      setOwnCvId(dispatch, resumeItemId);
    }
  };

  useEffect(() => {
    if (isCopying) {
      setIsDisable(true)
    } else {
      setIsDisable(resumeItems.length >= maxCountResumeItems);
    }
  }, [resumeItems, isCopying]);

  return (
    <>
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab">{t(`cvSettings.title.label`)}</div>
      </div>
      <hr className="my-6" />

      {resumeItems.map((item, index) => (
        <Item
          key={`resumeItem-${item.id}`}
          item={item}
          index={index}
          isDisabled={isDisabled}
          deleteItem={deleteItem}
          copyItem={copyItem}
          setItemAsMain={setItemAsMain}
          onChange={onChange}
          activeResume={activeResume}
          isSale={isSale}
        />
      ))}
    </>
  );
};

export default CvSettingsTab;
