import React, { useContext, useEffect, useState } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as SortIcon } from '../../assets/images/icons/sort.svg';
import { ReactComponent as DownIcon } from '../../assets/images/icons/arrow-down.svg';
import { ReactComponent as EmptyViewsIcon } from '../../assets/images/views-empty.svg';
import { pathNames } from './helpers';
import AppContext from '../../context/AppContext';
import { GetResumeViews } from '../../services/GetResumeViews';
import { ViewDatesSection } from './ViewDatesSection';

const gridSizes = [28, 22, 26, 24];

export const AdminPanelViews = ({ open }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [views, setViews] = useState([]);

  const context = useContext(AppContext);
  const { state } = context;

  const { ownUserInfo, ownCvId, selectedCvId } = state;

  useEffect(() => {
    if (open === false) return;
    (async () => {
      const res = await GetResumeViews(selectedCvId || ownCvId);
      setViews(res);
    })();
  }, [open, ownUserInfo.username, ownCvId, selectedCvId]);

  const handleSort = (key) => {
    let direction = 'asc';

    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const compareStrings = (a, b) => {
      if (a < b) return direction === 'asc' ? -1 : 1;
      if (a > b) return direction === 'asc' ? 1 : -1;
      return 0;
    };

    const compareDates = (a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return direction === 'asc' ? dateA - dateB : dateB - dateA;
    };

    const sortedViews = [...views].sort((a, b) => {
      if (key === 'path') {
        return compareStrings(pathNames(a.path), pathNames(b.path));
      } else if (key === 'date') {
        return compareDates(a.date, b.date);
      }
      return 0;
    });

    setViews(sortedViews);
  };

  return (
    <DialogContent style={{ overflow: 'visible', padding: 0 }}>
      <ViewDatesSection resumeId={selectedCvId || ownCvId} setViews={setViews} />

      <div style={{ width: '100%', borderRadius: '4px', border: '1px solid #EDF2F7' }}>
        <div style={{ display: 'flex', padding: 12, background: '#EDF2F7' }}>
          <div style={{ flexBasis: `${gridSizes[0]}%` }}>Viewer</div>
          <div
            onClick={() => handleSort('path')}
            style={{
              cursor: 'pointer',
              flexBasis: `${gridSizes[1]}%`,
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div>Path</div> <DownIcon />
          </div>
          <div
            onClick={() => handleSort('date')}
            style={{
              cursor: 'pointer',
              flexBasis: `${gridSizes[2]}%`,
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div>Date, time</div> <SortIcon />
          </div>
          <div style={{ flexBasis: `${gridSizes[3]}%` }}>Shared by</div>
        </div>

        {views?.map((view, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                padding: '14px 12px',
                alignItems: 'center',
                background: index % 2 === 0 ? '#FFFFFF' : '#F7FAFC',
              }}
            >
              <div
                style={{
                  flexBasis: `${gridSizes[0]}%`,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {view.email}
              </div>
              <div style={{ flexBasis: `${gridSizes[1]}%`, fontSize: '14px' }}>
                {pathNames(view.path)}
                {view.path === 'link' && (
                  <div style={{ fontSize: '12px', color: '#718096' }}>
                    Expiry: {view.linkDetails?.untilDate}
                  </div>
                )}
              </div>
              <div style={{ flexBasis: `${gridSizes[2]}%` }}>
                {new Date(view.date).toLocaleDateString('en-US', {
                  month: 'long',
                  day: '2-digit',
                })}
                ,{' '}
                {new Date(view.date).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
              <div style={{ flexBasis: `${gridSizes[3]}%` }}>{view.linkDetails?.creator}</div>
            </div>
          );
        })}
      </div>
      {views?.length === 0 && (
        <div
          style={{
            flexDirection: 'column',
            padding: '36px 0 12px 0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: 16,
          }}
        >
          <EmptyViewsIcon />
          <div style={{ color: '#6C757D', fontSize: 14 }}>No views recorded yet</div>
        </div>
      )}
    </DialogContent>
  );
};
